import { useEffect, useState } from 'react';
import { Grid, Card } from '@mui/material';
import ButtonCustom from '@/components/Buttons/ButtonCustom';
import { Exposure } from '@mui/icons-material';
import TitlePage from '@/components/TitlePage';
import { ModalBanner } from './ModalBanner';
import CustomLightbox from '@/components/CustomLightbox';
import { useDispatch, useSelector } from 'react-redux';
import { bannerData, getBanner } from '@/redux/slices/banner';
import { LoadingPage } from '../LoadingPage';
import { cacheBustingImage } from '@/shared/utils/common';

export const SettingBanner = () => {
  const {
    listGetBanner: { data },
    loadingGetBanner,
  } = useSelector(bannerData);
  const dispatch = useDispatch<any>();
  const [openModal, setOpenModal] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  // Thêm state để xử lý cache-busting
  const [imageUrl, setImageUrl] = useState<string>('');

  const clickChangeImage = () => {
    setOpenModal(true);
  };

  const onClose = () => setIsOpen(false);

  const handeOpenImage = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    dispatch(getBanner());
  }, [dispatch]);

  // Cập nhật URL ảnh với cache-busting khi data thay đổi
  useEffect(() => {
    if (data) {
      setImageUrl(cacheBustingImage(data));
    }
  }, [data]);

  return (
    <Card sx={{ marginTop: 2, p: 2 }}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={2}>
          <strong>Hình ảnh Banner: </strong>
        </Grid>
        <Grid item xs={10} width="100%">
          {loadingGetBanner ? (
            <LoadingPage />
          ) : (
            <img src={imageUrl} alt="banner" className="banner-image" onClick={handeOpenImage} />
          )}
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={9}>
          <ButtonCustom
            icon={<Exposure />}
            color="primary"
            title="Thay đổi ảnh"
            variant="contained"
            onClick={clickChangeImage}
            fullWidth={false}
            className="button-sx"
          />
        </Grid>
      </Grid>
      {isOpen && <CustomLightbox images={imageUrl} isOpen={isOpen} onClose={onClose} />}
      {openModal && (
        <ModalBanner openModal={openModal} setOpenModal={setOpenModal} bannerUrl={imageUrl} setImageUrl={setImageUrl} />
      )}
    </Card>
  );
};
