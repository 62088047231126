import { SelectFormProps } from '@/interfaces/index';
import { FormControl, MenuItem, Select, InputLabel, Typography, FormHelperText, CircularProgress } from '@mui/material';
import { Controller } from 'react-hook-form';
import _ from 'lodash';
import { handleError } from '@/shared/utils/common';

export const SelectForm = ({
  control,
  options,
  label,
  name,
  size = 'small',
  errors,
  arrayErrors,
  isLoading,
  readOnly,
  onChange,
  sizeLabel = 'small',
  value,
  multiple,
  defaultAll = false,
  ...rest
}: SelectFormProps) => {
  const lang = window.lang;
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <>
            <FormControl fullWidth error={!!arrayErrors || !!errors?.[name]}>
              <InputLabel size={sizeLabel}>
                {label}
                {rest?.required && <span style={{ color: 'red' }}> *</span>}
              </InputLabel>
              <Select
                size={size}
                {...field}
                multiple={multiple}
                readOnly={readOnly}
                {...rest}
                label={label}
                value={value || field.value}
                defaultValue={value}
                onChange={(event) => {
                  field.onChange(event);
                  if (onChange) {
                    onChange(event);
                  }
                }}
                IconComponent={
                  isLoading ? () => <CircularProgress size={20} style={{ marginRight: '5px' }} /> : undefined
                }
              >
                {defaultAll && (
                  <MenuItem value={0} className="text-all-select">
                    <span>{lang.select.defaultAll} </span>
                  </MenuItem>
                )}
                {options?.map((item) => (
                  <MenuItem
                    key={item?.value || item?.id || item?.productId}
                    value={item?.value || item?.id || item?.productId}
                  >
                    <Typography className="option-custom">
                      {item?.image && (
                        <img
                          src={item?.image}
                          onError={handleError}
                          style={{ height: '100%', width: '70px', marginRight: 2 }}
                        />
                      )}
                      {item?.label || item?.description || item?.name || item?.paymentName || item?.productName}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
              {arrayErrors && <FormHelperText>{arrayErrors?.message}</FormHelperText>}
              {errors?.[name] && <FormHelperText>{errors[name]?.message}</FormHelperText>}
            </FormControl>
          </>
        )}
      />
    </>
  );
};
