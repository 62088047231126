import {
  enumStatus,
  ACTIONS,
  PAGE_SIZE,
  enumTripStatus,
  enumDriverStatus,
  renderTripLocations,
  PAGE_SIZE_30,
  enumOffer,
} from './common';
import {
  formatCurrency,
  formatDateTimeNotTime,
  formatDateTimeNotUTC,
  numberPaging,
  parseFormatDateTime,
  reverseNumbering,
} from '../utils/common';
import { Grid, IconButton, Typography } from '@mui/material';
import { Delete, StarRate } from '@mui/icons-material';
import { ALL, CANCEL, COMPLETE, FINISHED, IS_BLOCK, NEW } from './text';
import { ROLE_ADMIN } from '../config/setting';
import { FaEdit } from 'react-icons/fa';
import { IoMdInfinite } from 'react-icons/io';
export function parseGroupCustomerData(data, currentPage) {
  if (!data) return;
  return data?.map((item, index) => ({
    id: item?.id,
    stt: numberPaging(currentPage, index),
    name: item?.name,
    create_at: item?.creationTime,
    isActive: item?.isActive,
    actions: [ACTIONS.EDIT, ACTIONS.DELETE],
    subData: {
      description: item?.description,
      code: item?.code,
    },
  }));
}

export function parseDataCustomer(data, currentPage, enumStatus, totalItems, role) {
  if (!data) return;
  const isBlock = 2;
  return data?.map((item, index) => ({
    stt: reverseNumbering(PAGE_SIZE, totalItems, currentPage, index),
    id: item?.id,
    name: (
      <Grid className="tab-name-width">
        <Typography>{item?.fullName || `${item?.surnameAndMiddleName} ${item?.lastName}`}</Typography>
        <Typography>{item?.phoneNumber}</Typography>
      </Grid>
    ),
    email: item?.email,
    province: <Typography sx={{ whiteSpace: 'nowrap', width: 'auto' }}>{item?.provinceName}</Typography>,
    status: <div className="container-nav">{enumStatus(item?.status)}</div>,
    date: item?.creationTime ? formatDateTimeNotUTC(item?.creationTime) : '-',
    actions:
      role === ROLE_ADMIN
        ? [ACTIONS.VIEW_DETAIL, ACTIONS.RESET_PASSWORD, item?.status === isBlock ? ACTIONS.UN_BLOCK : ACTIONS.BLOCK]
        : [ACTIONS.VIEW_DETAIL],
    isTextBlock: 'Chặn khách hàng',
    isTextUnBlock: 'Bỏ chặn khách hàng',
  }));
}

export function parseDataAccount(data, totalCount, currentPage, enumStatus, enumRoles, profileId) {
  if (!data) return;
  return data?.map((item, index) => ({
    stt: reverseNumbering(PAGE_SIZE, totalCount, currentPage, index),
    id: item?.id,
    userName: item?.userName,
    name: (
      <Grid className="tab-name-width">
        <Typography>{item?.fullName || `${item?.surnameAndMiddleName} ${item?.lastName}`}</Typography>
        <Typography>{item?.phoneNumber}</Typography>
      </Grid>
    ),
    email: item?.email,
    role: <Typography sx={{ whiteSpace: 'nowrap', width: 'auto' }}>{enumRoles(item?.roles)}</Typography>,
    status: <div className="container-nav">{enumStatus(item?.status)}</div>,
    date: formatDateTimeNotUTC(item?.createdDate),
    actions:
      item.userId === profileId ? [ACTIONS.VIEW_DETAIL] : [ACTIONS.VIEW_DETAIL, ACTIONS.RESET_PASSWORD, ACTIONS.DELETE],
    isTextDelete: 'Xóa tài khoản',
    isTextResetPassword: 'Cấp lại mật khẩu',
  }));
}

export function parseStatictisVote(data, currentPage, handleNavigate, renderStar) {
  if (!data) return;

  return data?.map((item, index) => ({
    stt: numberPaging(currentPage, index),
    id: item?.id,
    customer: (
      <>
        <div>{item?.customerName}</div>
        <div>{item?.customerPhoneNumber}</div>
      </>
    ),
    driver: (
      <>
        <div>{item?.driverName}</div>
        <div>{item?.driverPhoneNumber}</div>
      </>
    ),
    codeTrip: (
      <div onClick={() => handleNavigate(item?.tripId)} className="code-trip-detail">
        {item?.code}
      </div>
    ),
    vote: renderStar(item?.rating),
    comment: item?.review,
    date: formatDateTimeNotTime(item?.reviewedTime),
  }));
}

export function parseStatictisTrip(data, currentPage) {
  if (!data) return;

  return data?.map((item, index) => ({
    stt: numberPaging(currentPage, index),
    code: item?.code,
    id: item?.id,
    driver: (
      <>
        <div>{item?.driver}</div>
        <div>{item?.phoneDriver}</div>
      </>
    ),
    customer: (
      <>
        <div>{item?.customer}</div>
        <div>{item?.phoneNumber}</div>
      </>
    ),
    address1: item?.address1,
    address2: item?.address2,
    status: enumTripStatus(item?.status),
    price: item?.price,
    payment: item?.payment,
    date: item?.date,
    actions: [ACTIONS.VIEW_DETAIL],
  }));
}

export function parseDataRevenueStatictis(data, currentPage) {
  if (!data) return;

  return data?.map((item, index) => ({
    stt: numberPaging(currentPage, index),
    date: formatDateTimeNotTime(item?.createdTime),
    totalTrip: item?.totalTrip,
    totalComplete: item?.totalComplete,
    totalCancel: item?.totalCancel,
    totalRevenue: formatCurrency(item?.totalRevenue, false),
    totalCash: formatCurrency(item?.totalCash, false),
    totalVNPay: formatCurrency(item?.totalVNPay, false),
  }));
}

export function parseDataTrip1Statictis(data, currentPage) {
  if (!data) return;

  return data?.map((item, index) => ({
    stt: numberPaging(currentPage, index),
    date: formatDateTimeNotTime(item?.createdTime),
    totalTrip: item?.totalTrip,
    totalComplete: item?.totalComplete,
    totalCancel: item?.totalCancel,
    totalTripCustomerCancel: item?.totalTripCustomerCancel,
    totalTripDriverCancel: item?.totalTripDriverCancel,
    totalTripEmployeeCancel: item?.totalTripEmployeeCancel,
  }));
}

export function parseDataVoteManagement(data, currentPage, handleDelete) {
  if (!data) return;

  return data?.map((item, index) => ({
    stt: reverseNumbering(PAGE_SIZE, 2, currentPage, index),
    id: item?.id,
    name: item?.name,
    driver: item?.driver,
    code: item?.code,
    vote: (
      <div className="vote-item">
        <strong>{item?.vote}</strong> <StarRate className="star-icon" />
      </div>
    ),
    about: item?.about,
    notAbout: item?.notAbout,
    date: item?.date,
    iconDelete: (
      <IconButton color="error" onClick={() => handleDelete(item.id)}>
        <Delete />
      </IconButton>
    ),
  }));
}

export function parseDataHistoryCustomer(data, totalCount, currentPage, navigateToTrip) {
  if (!data) return;

  const StartingPoint = 0;
  const StoppingPoint = 1;
  const DestinationPoint = 2;

  return data?.map((item, index) => ({
    stt: reverseNumbering(PAGE_SIZE, totalCount, currentPage, index),
    id: item?.id,
    code: (
      <Grid onClick={() => navigateToTrip(item?.id, true)} className="code-trip-detail">
        {item?.code}
      </Grid>
    ),
    date: formatDateTimeNotUTC(item?.creationTime),
    date2: renderTripLocations(item?.tripLocations, StartingPoint),
    date3: renderTripLocations(item?.tripLocations, StoppingPoint),
    date4: renderTripLocations(item?.tripLocations, DestinationPoint),
    status: <div className="container-nav">{enumTripStatus(item.status)}</div>,
    paymentSettingName: item?.paymentSettingName,
    totalMoney: formatCurrency(item?.totalPrice, false),
    nameDriver: (
      <>
        <div>{item?.driverName}</div>
        <div>{item?.driverPhoneNumber}</div>
      </>
    ),
  }));
}

export function parseDataVehicleClass(data, currentPage, enumStatus, totalItems) {
  if (!data) return;

  return data?.map((item, index) => ({
    stt: numberPaging(currentPage, index),
    id: item?.id,
    className: (
      <Grid sx={{ whiteSpace: 'nowrap', width: 'auto', maxWidth: '300px' }}>
        <Typography>{item?.name || `${item?.surnameAndMiddleName} ${item?.lastName}`}</Typography>
        <Typography>{item?.phoneNumber}</Typography>
      </Grid>
    ),
    content: item?.desciption,
    status: <div className="container-nav">{enumStatus(item?.status)}</div>,
    actions: [ACTIONS.EDIT, ACTIONS.DELETE],
    isTextDelete: 'Xóa hạng xe',
  }));
}

export function parseDriverData(data, currentPage, totalItem, role) {
  if (!data) return;

  return data?.map((item, index) => ({
    id: item?.id,
    userId: item?.userId,
    stt: reverseNumbering(PAGE_SIZE, totalItem, currentPage, index),
    name: (
      <Grid className="tab-name-width">
        <Typography>{item?.fullName || `${item?.surnameAndMiddleName} ${item?.lastName}`}</Typography>
        <Typography>{item?.phoneNumber}</Typography>
      </Grid>
    ),
    email: item?.email,
    address: item?.address,
    status: <div className="container-nav">{enumStatus(item?.status)}</div>,
    date: formatDateTimeNotUTC(item?.createdDate),
    actions:
      role === ROLE_ADMIN
        ? [
            ACTIONS.VIEW_DETAIL,
            ACTIONS.RESET_PASSWORD,
            item.status === IS_BLOCK ? ACTIONS.UN_BLOCK : ACTIONS.REJECT,
            ACTIONS.DELETE,
          ]
        : [ACTIONS.VIEW_DETAIL, item.status === IS_BLOCK ? ACTIONS.UN_BLOCK : ACTIONS.REJECT],
    isTextUnBlock: 'Bỏ chặn tài xế',
    isDelete: item?.isDelete ? true : false,
    isTextResetPassword: 'Cấp lại mật khẩu',
  }));
}

export function parseDataNotification(data, totalCount, handleParseData, handleParseDataIdTrip, currentPage, read) {
  if (!data) return;

  return data?.map((item, index) => ({
    stt: reverseNumbering(PAGE_SIZE_30, totalCount, currentPage, index),
    id: item?.id,
    // dateTime: readableTime(item?.creationTime),
    dateTime: formatDateTimeNotUTC(item?.creationTime),
    contentNotification: (
      <div onClick={() => read(item)} className="content-noti">
        {handleParseData(item, item?.data)}
      </div>
    ),
    isRead: item?.isRead,
  }));
}

export function parseListOffer(data, totalCount, currentPage) {
  if (!data) return;
  const GENERAL_OFFER_API = 0;
  return data?.map((item, index) => ({
    stt: reverseNumbering(PAGE_SIZE, totalCount, currentPage, index),
    id: item.id,
    discountName: item.discountName,
    descriptionOffer: <div className="about-offer" dangerouslySetInnerHTML={{ __html: item.description }} />,
    startDate: parseFormatDateTime(item.startDate),
    endDate: parseFormatDateTime(item.endDate),
    total: (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {formatCurrency(item.usageCount, false) || 0} /{' '}
        {item.totalDiscount ? (
          formatCurrency(item.totalDiscount || 0, false)
        ) : (
          <IoMdInfinite style={{ fontSize: '16px' }} />
        )}
      </div>
    ),
    type: item.type === GENERAL_OFFER_API ? 'Theo phần trăm' : 'Theo số tiền',
    status: <div className="container-nav">{enumOffer(item?.status)}</div>,
    actions: [ACTIONS.VIEW_DETAIL, ACTIONS.DELETE],
  }));
}

export function parseTripsHistoryData(data, totalCount, currentPage, navigateToTrip) {
  if (!data) return;

  const StartingPoint = 0;
  const StoppingPoint = 1;
  const DestinationPoint = 2;

  return data?.map((item, index) => ({
    stt: reverseNumbering(PAGE_SIZE, totalCount, currentPage, index),
    id: item?.id,
    code: (
      <Grid onClick={() => navigateToTrip(item?.id)} className="code-trip-detail">
        {item?.code}
      </Grid>
    ),
    date: formatDateTimeNotUTC(item?.createdDate),
    name: (
      <Grid className="tab-name-width">
        <Typography>{item?.fullName}</Typography>
        <Typography>{item?.phoneNumber}</Typography>
      </Grid>
    ),
    date2: renderTripLocations(item?.tripLocations, StartingPoint),
    date3: renderTripLocations(item?.tripLocations, StoppingPoint),
    date4: renderTripLocations(item?.tripLocations, DestinationPoint),
    status: <div className="container-nav">{enumTripStatus(item?.status)}</div>,
    paymentSettingName: item?.paymentSettingName,
    totalMoney: formatCurrency(item?.totalPrice, false),
  }));
}

export function parseSystemPriceData(data, currentPage, totalItem) {
  if (!data) return;

  return data?.map((item, index) => {
    const vehicleName = item.vehicleClassId ? item.vehicleClassName : ALL;
    return {
      stt: numberPaging(currentPage, index),
      id: item.id,
      provinceName: item.provinceName,
      vehicleName,
      firstPrice: formatCurrency(item.firstPrice, false),
      middlePrice: formatCurrency(item.middlePrice, false),
      finalPrice: formatCurrency(item.finalPrice, false),
      surcharge: formatCurrency(item.surcharge, false),
      actions: [ACTIONS.EDIT, ACTIONS.DELETE],
    };
  });
}

export function parseTripDetailHistory(data, currentPage, totalItems, pageSize) {
  if (!data) return;

  return data?.map((item, index) => ({
    stt: reverseNumbering(pageSize, totalItems, currentPage, index),
    time: <Typography sx={{ whiteSpace: 'pre-line' }}>{formatDateTimeNotUTC(item?.dateTimeUpdate)}</Typography>,
    name: item?.userName,
    role: item?.roleName,
    content: item?.content,
  }));
}

export function parseTripManagementData(
  data,
  totalRecord,
  currentPage,
  handle,
  pageSize,
  handleEditPriceWaiting,
  role,
) {
  if (!data) return;

  const isChangeWaittingMoney = [CANCEL, FINISHED, NEW, COMPLETE];
  const isChangeCancelTrip = [COMPLETE, CANCEL];
  return data?.map((item, index) => {
    return {
      stt: reverseNumbering(pageSize, totalRecord, currentPage, index),
      id: item?.id,
      code: item?.code,
      driverName: (
        <Grid className="tab-name-width">
          <Typography>{item?.driverName || `${item?.surnameAndMiddleName} ${item?.lastName}`}</Typography>
          <Typography>{item?.driverPhoneNumber}</Typography>
        </Grid>
      ),
      customerName: (
        <Grid className="tab-name-width">
          <Typography>{item?.customerName || `${item?.surnameAndMiddleName} ${item?.lastName}`}</Typography>
          <Typography>{item?.customerPhoneNumber}</Typography>
        </Grid>
      ),
      addressStart: item?.addressStart,
      addressEnd: item?.addressEnd,
      status: <div className="container-nav">{enumTripStatus(item?.statusTrip)}</div>,
      provisional: formatCurrency(item?.provisionalPrice, false),
      waittingMoney:
        !isChangeWaittingMoney.includes(item?.statusTrip) && role === ROLE_ADMIN ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
            <div>{formatCurrency(item?.waittingFee, false)}</div>
            <FaEdit onClick={() => handleEditPriceWaiting(item?.id)} className="write-trip-icon" />
          </div>
        ) : (
          <div className="total-money">{formatCurrency(item?.waittingFee, false)}</div>
        ),
      totalMoney: formatCurrency(item?.totalPrice, false),
      paymentName: item?.paymentName,
      date: formatDateTimeNotUTC(item?.creationTime),
      driverStatus: (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {enumDriverStatus(item?.isAssign ? 1 : 0, item, handle, item?.statusTrip === CANCEL)}
        </div>
      ),
      isTextBlock: 'Hủy hành trình',
      actions:
        item?.statusTrip === FINISHED
          ? [ACTIONS.VIEW_DETAIL, ACTIONS.PAID, ACTIONS.BLOCK]
          : isChangeCancelTrip.includes(item?.statusTrip)
          ? [ACTIONS.VIEW_DETAIL]
          : [ACTIONS.VIEW_DETAIL, ACTIONS.BLOCK],
    };
  });
}
