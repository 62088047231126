import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FormHelperText,
  Grid,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useEffect, useState } from 'react';
import CustomImage from '@/components/CustomImage';
import { showErrorToast, showSuccessToast } from '@/hooks/useCustomToast';
import { allowedTypes, cacheBustingImage } from '@/shared/utils/common';
import ButtonCustom from '@/components/Buttons/ButtonCustom';
import ConfirmPopup from '@/components/ConfirmPopup';
import CustomLightbox from '@/components/CustomLightbox';
import { useDispatch, useSelector } from 'react-redux';
import { priceSystemData, putImageBanner } from '@/redux/slices/priceSystem';
import { CustomBackdrop } from '@/components/CustomBackdrop';
import { bannerData, getBanner, postBanner } from '@/redux/slices/banner';
import { JPG } from '@/shared/constant/text';

export const ModalBanner = ({ openModal, setOpenModal, bannerUrl, setImageUrl }) => {
  const [img, setImg] = useState<string>('');
  const [imgFile, setImgFile] = useState<File>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { openPopup, ConfirmationPopup } = ConfirmPopup();
  const dispatch = useDispatch<any>();
  const { loadingPostBanner } = useSelector(bannerData);

  const lang = window.lang;
  const schema = yup.object().shape({
    image: yup.string().nullable().required('Ảnh không được để trống!'),
  });
  const handleClose = () => {
    setOpenModal(false);
  };
  const text = img ? '+ Chọn ảnh khác' : '+ Thêm ảnh';

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      image: '',
    },
  });
  const image = watch('image');
  const errorImage = errors && errors.image && !image;

  const onSubmit = (data) => {
    const dispatchAPI = () => {
      const payload = {
        params: {
          Banner: imgFile,
        },
        callbackSuccess: () => {
          dispatch(getBanner())
            .unwrap()
            .then((res) => {
              if (res?.data) {
                setImageUrl(cacheBustingImage(res.data));
              }
            });
          reset();
          setOpenModal(false);
          showSuccessToast('Sửa ảnh banner thành công!');
        },
        callbackError: (isError) => {
          showErrorToast(isError);
        },
      };

      dispatch(postBanner(payload));
    };

    openPopup('Bạn có chắc chắn muốn chỉnh sửa ảnh banner không?', () => dispatchAPI());
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];

    if (!file || !allowedTypes.includes(file.type)) {
      showErrorToast('Vui lòng chọn đúng định dạng ảnh');
      return;
    }

    const imgUrl = URL.createObjectURL(file);
    setImg(imgUrl);
    setImgFile(file);
  };

  const handleDeleteImage = () => {
    setImg('');
    setValue('image', '');
    setImgFile(undefined);
  };

  const onClose = () => setIsOpen(false);

  const handeOpenImage = () => {
    setIsOpen(true);
  };

  // Thêm cache-busting vào URL ảnh
  useEffect(() => {
    if (bannerUrl) {
      setImg(cacheBustingImage(bannerUrl));
    }
  }, [bannerUrl]);

  return (
    <>
      <Dialog
        open={openModal}
        fullWidth
        PaperProps={{
          style: {
            width: '100%',
            maxHeight: '100%',
          },
        }}
      >
        <>
          <DialogTitle>
            <Typography className="modal-trip" variant="h6" mb={1} pb={1}>
              <b>Chỉnh sửa ảnh Banner</b>
            </Typography>
          </DialogTitle>
          <DialogContent className="modal-dialog-trip">
            <Grid container gap={2}>
              <Grid item xs={12}>
                <label className="add-img" htmlFor="img-commodity-upload">
                  <Typography className="add-img-text">
                    {text} <span className="required">*</span>
                  </Typography>
                </label>
                {errorImage && (
                  <FormHelperText error sx={{ marginLeft: '14px' }}>
                    {errors?.image?.message}
                  </FormHelperText>
                )}

                <Controller
                  name="image"
                  control={control}
                  render={({ field }) => {
                    return (
                      <TextField
                        className="image-hidden"
                        inputProps={{
                          accept: 'image/*',
                        }}
                        id="img-commodity-upload"
                        type="file"
                        onChange={(event) => {
                          handleFileSelect(event);
                          field.onChange(event);
                        }}
                      />
                    );
                  }}
                />
              </Grid>
              {img && (
                <Grid item md={12} width="100%">
                  <CustomImage
                    className="img-driver-id"
                    src={img}
                    style={{ margin: '5px' }}
                    alt="default_image"
                    onDelete={handleDeleteImage}
                    onClickImage={handeOpenImage}
                  />
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions className="dialog-button-action">
            <Grid container direction="row" justifyContent="center" alignItems="center" gap={2}>
              <ButtonCustom fullWidth={false} color="error" onClick={handleClose} title={lang.title.titlePage.close} />
              <ButtonCustom
                fullWidth={false}
                color="primary"
                onClick={handleSubmit(onSubmit)}
                title={lang.title.titlePage.confirm}
                disabled={!imgFile}
              />
            </Grid>
            <ConfirmationPopup />
          </DialogActions>
          <CustomBackdrop open={loadingPostBanner} />
          {isOpen && <CustomLightbox images={img} isOpen={isOpen} onClose={onClose} />}
        </>
      </Dialog>
    </>
  );
};
