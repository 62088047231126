import { CookiesStorage } from '../config/cookie';
import { Chip, IconButton, Typography } from '@mui/material';
import { Block, Close, Done, DriveEta, RemoveCircleOutline } from '@mui/icons-material';
import { optionsRoleAccount, optionsStatusVehicle } from '../options';
import React from 'react';
import {
  ReceiveMessageBookADriver,
  ReceiveMessageWhenCustomerOrDriverCancelTrip,
  ReceiveMessageWhenDriverAccept,
  ReceiveMessageWhenDriverFilldWaitingFee,
  ReceiveMessageWhenDriverFinishedWaiting,
  ReceiveMessageWhenDriverNearBy,
  ReceiveMessageWhenDriverRefuses,
  ReceiveMessageWhenDriverStartWaiting,
} from './text';
import { DateOrTimeView } from '@mui/x-date-pickers';

export const CookieKey = {
  token: 'token',
  account: 'account',
};

export const ACTIONS = {
  RESET_PASSWORD: 'reset_password',
  VIEW_DETAIL: 'view_detail',
  EDIT: 'edit',
  DELETE: 'delete',
  BLOCK: 'block',
  CANCEL: 'cancel',
  RE_STATUS: 'reset',
  APPROVE: 'approve',
  REJECT: 'reject',
  UN_BLOCK: 'un_block',
  PAID: 'paid',
};

export const genderSelect = [
  {
    value: 1,
    label: 'Nam',
  },
  {
    value: 0,
    label: 'Nữ',
  },
];

export const usernameRegExpUpCase = /^[a-z0-9_]+$/;
export const phonenumberRegExp = /^0[0-9]*$/;
export const LOGIN = 'login';
export const LOGOUT_URL = 'logout';
export const img = 'https://imgs.bharatmatrimony.com/bmimgs/login/login-otp-banner.png';
export const phonenumberReg = /^(?:\+?84|0)(3[2-9]|5[2689]|7[06-9]|8[1-9]|9[0-9])\d{7}$/;

export const RegDateTime = 'dd/MM/yyyy HH:mm:ss';
export const DateRegExp = 'yyyy-MM-dd';
export const DateOfBirth = 'dd/MM/yyyy';
export const usernameRegExp = /^(?=[a-z\d]*[a-z]){2}[a-z-\d]{5,}$/;
export const passwordRegExp = /^\S{6,}$/;
export const emailRegExp = /^(?:\S+@\S+\.\S+|\s*)$/;
export const addressRegExp = /^(?!^\s*$)[\p{L}\d\s./,-]+$/u;
export const dateRegExp = /^(?:19|20)\d\d-(?:0[1-9]|1[0-2])-(?:0[1-9]|[1-2]\d|3[0-1])$/;
export const notSpaceRegExp = /^[^\s]{6,}$/;
export const min1Character = /^\s*(\S\s*){1,}$/;
export const fullnameRegExp = /^[A-Za-zÀ-ỹ\s]+$/;
export const fullnameRegExpDriver = /^[A-Za-zÀ-ỹ\s0-9]+$/;
export const noSpaceRegExp = /^(?!\s)(.*?)(?<!\s)$/;
export const notFullSpace = /^.*\S.*$/;
export const phoneNumberRegExp = /^(0\d{9}|[+\d\s]+|[0-9]+)$/;
export const accountNumberBank = /^[A-Za-z0-9]+$/;
export const regNameStore = /^[A-Za-zÀ-ỹ\s\d]+$/;
export const regNameGroupustomer = /^[a-zA-Z\sÀ-ỹ_]+$/u;

export const drawerWidth = 250;

export const PAGE_SIZE = 20;
export const PAGE_SIZE_30 = 30;
export const PAGE_SIZE_10 = 10;
export const NOT_PAGE_SIZE = 999999;
export const PAGE_SIZE_DEBT = 5;

export const PROVICE_HUE = 46;

export const ROUTER_BACK = -1;

export const CONST_TYPE_PARAMS = {
  IMPORT: 'import',
  PAYSHELL: 'payshell',
};

export const DRIVER_PROFILE = {
  PHONE: 'phoneNumber',
  NAME: 'fullName',
  EMAIL: 'email',
  ADDRESS: 'address',
  STATUS: 'status',
};

export const CONST_ROUTER = {
  LOGIN: '/login',
  DASHBOARD: '/',
  ADD_OFFER: '/discount-add',
  DETAIL_OFFER: (id: string) => `/discount-add/${id}`,
  DETAIL_OFFER_ITEM: `/discount-add/:itemId`,
  PROFILE: '/profile',
  OFFER: '/discount',
  REQUEST_DELETE_ACCOUNT: '/request-delete-account',
  REQUEST_DELETE_DRIVER: '/request-delete-account/driver',
  USER_POLICY: {
    PRIVACY: '/privacy-policy',
    CLAUSE: '/terms-of-use',
  },
  DRIVER_POLICY: {
    PRIVACY: '/privacy-policy/driver',
    CLAUSE: '/terms-of-use/driver',
  },
  DRIVER_ACTIVE: 'driver-active',
  DENIED: '/denied',
  PROFILE_USER: 'profile-user',
  USER: '/user',
  VOTE_CUSTOMER: '/vote',
  DRIVER_MANAGEMENT: '/driver-management',
  DRIVER_DETAIL: '/driver-management/:itemId',
  DRIVER_DETAIL_ITEM: (id: string) => `/driver-management/${id}`,
  UPDATE_PASS: '/update-pass',
  HIRE_DRIVER: '/hire-adriver',
  CUSTOMER_MANAGEMENT: '/customer',
  CUSTOMER_MANAGEMENT_CATEGORY: '/customer/category',
  DETAIL_CUSTOMER: '/management-customer/:itemId',
  FORGET_PASS: '/forget-pass',
  VEHICLE_CLASS: '/management-vehicle-class',
  BANNER: '/banner',
  MOVE: '/move',
  STATISTICAL: '/statistical',
  SYSTEM: '/system',
  REVENUE: '/revenue',
  NOTIFICATION: '/notification',
  VEHICLE_CLASS_ADD: '/management-vehicle-class-add',
  MANAGEMENT_CUSTOMER: '/management-customer',
  ADD_DRIVER: '/driver-management-add',
  DASH_BOARD_CHART: '/dashboard',
  ACCOUNT: '/account',
  ADD_ACCOUNT: '/account-add',
  EDIT_ACCOUNT: (id: string) => `/account-detail/${id}`,
  EDIT_ACCOUNT_ROUTER: `/account-detail/:itemId`,
  ADD_CUSTOMER: '/management-customer-add',
  DETAIL_CUSTOMER_ITEM: (id: string) => `/management-customer/${id}`,
  DETAIL_VEHICLE_ITEM: (id?: string) => `/management-vehicle-class/${id}`,
  DETAIL_VEHICLE: `/management-vehicle-class/:itemId`,
  TRIP_MANAGEMENT: '/trip-management',
  TRIP_DETAIL2: '/trip-management/logTrip/:itemId',
  TRIP_DETAIL1: '/trip-management/detailTrip/:itemId',
  TRIP_DETAIL1_ITEM: (id) => `/trip-management/detailTrip/${id}`,
  PAYMENT_POLICY: '/payment-policy',
  ADD_TRIP: '/trip-management/add-trip',
  PAYMENT_SETTING: '/payment-setting',
  TRIP_STATISTICS: '/trip-statistics',
  EVALUATION_STATISTICS: '/evaluation-statistics',
  SYSTEM_PRICE_SETTING: '/system-price-setting',
  AREA_PRICE_DETAIL: '/system-price-setting/:itemId',
  AREA_PRICE_DETAIL_ITEM: (id: string) => `/system-price-setting/${id}`,
  ADD_AREA_PRICE: '/system-price-setting-add',
};

export const employeeAccessibleRoutes = [
  CONST_ROUTER.DRIVER_MANAGEMENT,
  CONST_ROUTER.DRIVER_DETAIL,
  CONST_ROUTER.ADD_DRIVER,
  CONST_ROUTER.DRIVER_ACTIVE,
  CONST_ROUTER.MANAGEMENT_CUSTOMER,
  CONST_ROUTER.ADD_CUSTOMER,
  CONST_ROUTER.DETAIL_CUSTOMER,
  CONST_ROUTER.PROFILE_USER,
  CONST_ROUTER.TRIP_MANAGEMENT,
  CONST_ROUTER.TRIP_DETAIL1,
  CONST_ROUTER.TRIP_DETAIL2,
  CONST_ROUTER.ADD_TRIP,
  CONST_ROUTER.NOTIFICATION,
  CONST_ROUTER.DASH_BOARD_CHART,
  CONST_ROUTER.UPDATE_PASS,
];

export const ADMIN = 1;
export const OFFICE = 2;
export const TECHNICAL = 3;

export const optionFormData = `headers:{'Content-Type':'multipart/form-data'}`;

export const formData = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};
export const viewDate: DateOrTimeView[] | any = ['year', 'month', 'day', 'hours', 'minutes'];
export const isChooseMaxDate = 477;
export const viewDayOfWeek = ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'];
export const dayOfWeekFormatter = (day) => {
  return viewDayOfWeek[day];
};
export const formHeader = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const setUserCookie = (token: any) => {
  CookiesStorage.setCookieData('token', token);
};

export const generateRandomId = () => {
  const randomNumber = Math.floor(Math.random() * 90000000) + 10000000; // Commodity random code from  100000-999999
  return randomNumber;
};

export const getCurrentDateTimeNumeric = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const currentDay = currentDate.getDate();
  const currentHours = currentDate.getHours();
  const currentMinutes = currentDate.getMinutes();
  const currentSeconds = currentDate.getSeconds();

  // Định dạng các thành phần thành chuỗi số và thêm 0 vào phía trước nếu cần
  const formattedYear = currentYear.toString().slice(-2); // Lấy 2 chữ số cuối cùng của năm
  const formattedMonth = currentMonth.toString().padStart(2, '0');
  const formattedDay = currentDay.toString().padStart(2, '0');
  const formattedHours = currentHours.toString().padStart(2, '0');
  const formattedMinutes = currentMinutes.toString().padStart(2, '0');
  const formattedSeconds = currentSeconds.toString().padStart(2, '0');

  return `${formattedDay}${formattedMonth}${formattedYear}${formattedHours}${formattedMinutes}${formattedSeconds}`;
};

export const COMMON = {
  CLICKAWAY: 'clickaway',
};

export const typeObjectNotification = (data, key) => {
  const checkStatus = {
    [ReceiveMessageBookADriver]: `<strong>Hành trình <span>${data?.code}</span> đã được khách hàng <span>${data?.customerName}</span> tạo mới thành công.Vui lòng gán tài xế cho hành trình này.</strong>`,
    [ReceiveMessageWhenDriverAccept]: `<strong>Tài xế <span>${data?.driverName}</span> đã chấp nhận hành trình <span>${data?.code}</span>.</strong>`,
    [ReceiveMessageWhenDriverRefuses]: `<strong>Tài xế <span>${
      data?.driverName || data?.cancellerName
    }</span> đã từ chối hành trình <span>${data?.code}</span>.</strong>`,
    [ReceiveMessageWhenCustomerOrDriverCancelTrip]: data?.isDriverCancel
      ? `<strong>Tài xế <span>${data?.cancellerName}</span> đã hủy hành trình <span>${data?.code}</span>.</strong>`
      : `<strong>Khách hàng <span>${data?.cancellerName}</span> đã hủy hành trình <span>${data?.code}</span>.</strong>`,
    [ReceiveMessageWhenDriverStartWaiting]: `<strong>Tài xế <span>${data?.driverName}</span> đã bắt đầu chờ hành trình <span>${data?.code}</span>.</strong>`,
    [ReceiveMessageWhenDriverFinishedWaiting]: `<strong>Tài xế <span>${data?.driverName}</span> đã kết thúc chờ hành trình <span>${data?.code}</span>.</strong>`,
    [ReceiveMessageWhenDriverFilldWaitingFee]: `<strong>Tài xế <span>${data?.driverName}</span> đã cập nhật giá chờ cho hành trình <span>${data?.code}</span>.</strong>`,
    [ReceiveMessageWhenDriverNearBy]: `<strong>Tài xế <span>${data?.driverName}</span> sắp kết thúc hành trình <span>${data?.code}</span>.</strong>`,
  };

  return checkStatus[key];
};

export const typeObjectNotificationList = (data, key) => {
  const checkStatus = {
    [ReceiveMessageBookADriver]: (
      <div>
        Hành trình
        <span className="color-blue tripId"> {data?.code} </span>
        đã được khách hàng
        {''} {data?.customerName} {data?.customerPhoneNumber && `(${data.customerPhoneNumber})`} {''} tạo mới thành
        công. Vui lòng gán tài xế cho hành trình này.
      </div>
    ),
    [ReceiveMessageWhenDriverAccept]: (
      <div>
        Tài xế
        {''} {data?.driverName} {''}
        đã chấp nhận hành trình
        <span className="color-blue tripId"> {data?.code}.</span>
      </div>
    ),
    [ReceiveMessageWhenDriverRefuses]: (
      <div>
        Tài xế
        {''} {data?.driverName || data?.cancellerName} đã từ chối hành trình{''}
        <span className="color-blue tripId"> {data?.code}.</span>
      </div>
    ),
    [ReceiveMessageWhenCustomerOrDriverCancelTrip]: (
      <>
        {!data?.isDriverCancel ? (
          <div>
            Khách hàng
            {''} {data?.cancellerName || data?.customerName} {''}
            đã hủy hành trình
            <span className="color-blue tripId"> {data?.code}.</span>
          </div>
        ) : (
          <div>
            Tài xế
            {''} {data?.cancellerName || data?.driverName} {''}
            đã hủy hành trình {''}
            <span className="color-blue tripId"> {data?.code}.</span>
          </div>
        )}
      </>
    ),
    [ReceiveMessageWhenDriverStartWaiting]: (
      <div>
        Tài xế
        {''} {data?.driverName} đã bắt đầu chờ hành trình{''}
        <span className="color-blue tripId"> {data?.code}.</span>
      </div>
    ),
    [ReceiveMessageWhenDriverFinishedWaiting]: (
      <div>
        Tài xế
        {''} {data?.driverName} đã kết thúc chờ hành trình{''}
        <span className="color-blue tripId"> {data?.code}.</span>
      </div>
    ),
    [ReceiveMessageWhenDriverFilldWaitingFee]: (
      <div>
        Tài xế
        {''} {data?.driverName} đã cập nhật giá chờ cho hành trình{''}
        <span className="color-blue tripId"> {data?.code}.</span>
      </div>
    ),
    [ReceiveMessageWhenDriverNearBy]: (
      <div>
        Tài xế
        {''} {data?.driverName} sắp kết thúc hành trình{''}
        <span className="color-blue tripId"> {data?.code}.</span>
      </div>
    ),
  };

  return checkStatus[key];
};

export const customMediaQuery = '(max-width:800px)';

export const QueryConfig = {
  STALETIME: 86400000,
};

export const MIN_DATE = 2000;

export const URL_IMG_PAYMENT = 'http://api.dev.platinumtechnology.vn/static-files';

export const BORDER_HEADER = '2px solid #878181';
export const BORDER_RIGHT = '2px solid #ccc';

// NEW HIRE A DRIVER
export const enumStatus = (value) => {
  const typeObject = {
    1: <Done color="success" />,
    2: <Close color="error" />,
    3: <Block color="error" />,
    4: <RemoveCircleOutline color="error" />,
  };
  return typeObject[value];
};

export const enumOffer = (value) => {
  const typeObject = {
    0: <Done color="success" />,
    1: <Close color="error" />,
  };
  return typeObject[value];
};

export const renderTripLocations = (data, checkPoint) => {
  const StoppingPoint = 1;
  const arrayAddress = data.filter((obj) => obj.type === checkPoint);
  return arrayAddress?.map((item, index) => {
    if (checkPoint !== StoppingPoint) {
      return <div key={index}>{item?.address}</div>;
    }
    return (
      <div key={index}>
        <span className="color-red">Đ{index + 1}: </span>
        {item?.address}
      </div>
    );
  });
};

export const enumTripStatus = (value) => {
  const typeObject = {
    0: <Chip label="Đang tìm tài xế" className="status-chip-1" />,
    1: <Chip label="Đã tìm thấy tài" className="status-chip-2" />,
    2: <Chip label="Tài xế đã đến" className="status-chip-3" />,
    3: <Chip label="Đã xuất phát" className="status-chip-4" />,
    4: <Chip label="Đã kết thúc" className="status-chip-6" />,
    5: <Chip label="Đã thanh toán" className="status-chip-7" />,
    6: <Chip label="Đã hủy" className="status-chip-5" />,
  };
  return typeObject[value];
};

export const formatData = (data, characters) => {
  const dataArray = data.split(characters);
  const lastIdx = dataArray.length - 1;
  return dataArray.map((item, index) => (
    <React.Fragment key={index}>
      {item}
      {index !== lastIdx && <br />}
    </React.Fragment>
  ));
};

export const enumDriverStatus = (value, id, handle, disabled = false) => {
  return value === 0 ? (
    <IconButton className="button-not-hover" disabled={disabled}>
      <div
        className={disabled ? 'container-row driver-status-disabled' : 'container-row driver-status-waitting'}
        onClick={() => handle(id)}
      >
        <div className={disabled ? 'driver-status-disabled-icon' : 'driver-status-waitting-icon'}>
          <DriveEta fontSize="medium" />
        </div>
        <Typography>Chưa gán tài</Typography>
      </div>
    </IconButton>
  ) : (
    <div className="container-row driver-status-disabled">
      <div className="driver-status-disabled-icon">
        <DriveEta fontSize="medium" />
      </div>
      <Typography>Đã gán tài</Typography>
    </div>
  );
};

// Tabs
export const TabsDetailCustomerLabel = [
  { label: 'THÔNG TIN KHÁCH HÀNG' },
  { label: 'THÔNG TIN XE' },
  { label: 'LỊCH SỬ ĐẶT TÀI XẾ' },
];

export const TabsDriverDetailLabel = [{ label: 'THÔNG TIN TÀI XẾ' }, { label: 'LỊCH SỬ CHUYẾN ĐI' }];

export const TabsTripDetailLabel = [
  { label: 'CHI TIẾT HÀNH TRÌNH', url: (id) => `/trip-management/detailTrip/${id}` },
  { label: 'LỊCH SỬ THAY ĐỔI HÀNH TRÌNH', url: (id) => `/trip-management/logTrip/${id}` },
];
