import React from 'react';
import { Controller, Control, FieldErrors } from 'react-hook-form';
import { Autocomplete, TextField, CircularProgress, Checkbox, AutocompleteRenderInputParams } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface Option {
  label: string;
  value: string | number;
}

interface MultiSelectAutocompleteProps {
  control: Control<any>;
  name: string;
  options: Option[];
  label?: string;
  placeholder?: string;
  defaultValue?: (string | number)[];
  isLoading?: boolean;
  onChange?: (event: React.SyntheticEvent, value: (string | number)[]) => void;
  size?: 'small' | 'medium';
  errors: any;
  [key: string]: any;
}

export const MultiSelectAutocomplete = ({
  control,
  name,
  options,
  label,
  placeholder = '',
  defaultValue = [],
  isLoading = false,
  onChange,
  size = 'medium',
  errors,
  ...rest
}: MultiSelectAutocompleteProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value = [], onChange: fieldOnChange } }) => {
        const handleChange = (event: React.SyntheticEvent, newValue: Option[]) => {
          const newValueWithIds = newValue.map((item) => item.value);
          fieldOnChange(newValueWithIds);
          if (onChange) {
            onChange(event, newValueWithIds);
          }
        };

        return (
          <Autocomplete
            className="custom-autocomplete"
            multiple
            disableCloseOnSelect
            options={options}
            getOptionLabel={(option: Option) => option.label || ''}
            value={options.filter((option) => value.includes(option.value))}
            onChange={handleChange}
            renderOption={(props, option, { selected }) => {
              return (
                <li {...props}>
                  <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} style={{ marginRight: 8 }} />
                  {option.label}
                </li>
              );
            }}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <TextField
                {...params}
                label={label}
                placeholder={placeholder}
                size={size}
                variant="outlined"
                error={!!errors}
                helperText={errors}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {params.InputProps.endAdornment}
                      {isLoading && <CircularProgress size={20} />}
                    </>
                  ),
                }}
                className="custom-textfield"
              />
            )}
            {...rest}
          />
        );
      }}
    />
  );
};
