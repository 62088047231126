import { Card, FormControl, FormHelperText, Grid, useMediaQuery } from '@mui/material';
import { SelectSearch } from '@/common/Form';
import ButtonCustom from '@/components/Buttons/ButtonCustom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { CONST_ROUTER, NOT_PAGE_SIZE } from '@/shared/constant/common';
import { BackPage } from '@/components/BackPage';
import { schemaAddTrip } from '@/common/Schema';
import { showErrorToast, showSuccessToast } from '@/hooks/useCustomToast';
import { CustomBackdrop } from '@/components/CustomBackdrop';
import { useDispatch, useSelector } from 'react-redux';
import { convertOptionsDriver, convertOptionsPhoneNumber, formatCurrency } from '@/shared/utils/common';
import { useEffect, useRef, useState } from 'react';
import { getProvinceList } from '@/redux/slices/priceSystem';
import GoogleMapAutoComplete from './location';
import { driverData, getAvailableDriver, getDriverGoogleMap, postAddTrip } from '@/redux/slices/driver';
import { getPaymentSystemList, paymentSystemData } from '@/redux/slices/paymentSystem';
import { customerData, getDropdownListCustomerAvailable } from '@/redux/slices/customer';
import { getVehicleClass, vehicleClassData } from '@/redux/slices/vehicleClass';
import { temporaryPrice, tripData } from '@/redux/slices/trip';

export default function AddTrip() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');
  const lang = window.lang;
  const driverDataSelector = useSelector(driverData);
  const dataCustomer = useSelector(customerData);
  const dataSelectorPayment = useSelector(paymentSystemData);
  const dataVehicleSelector = useSelector(vehicleClassData);
  const dispatch = useDispatch<any>();
  const gridRef = useRef<any>(null);
  const dataTrip = useSelector(tripData);
  const [gridWidth, setGridWidth] = useState(null);
  const { listPaymentSystem, loadingListPaymentSystem } = dataSelectorPayment;
  const { listDropdownCustomerAvailabel, loadingListDropDownAvailabel } = dataCustomer;
  const { availableDriveList, loadingListDriverGoogleMap, loadingAddTrip } = driverDataSelector;
  const { listTemporaryPrice, loadingTemporaryPrice } = dataTrip;
  const dataVehicle = dataVehicleSelector?.listVehicle?.data;
  const driverList = convertOptionsDriver(availableDriveList);

  const START_POINT = 0;
  const STOP_POINT = 1;
  const DESATINATION_POINT = 2;

  const STOP = 'stop';
  const DEPARTURE = 'departure';
  const DESINATION = 'destination';

  const {
    control,
    handleSubmit,
    reset,
    register,
    setValue,
    clearErrors,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaAddTrip(lang)),
    defaultValues: {
      driver: '',
      customer: '',
      departure: { address: '', location: '', id: '' },
      stop: { address: '', location: '', id: '' },
      destination: { address: '', location: '', id: '' },
      price: '',
      payment: '',
      vehicle: '',
      distance: '',
    },
  });

  const stop = watch('stop') || {};
  const departure = watch('departure') || {};
  const destination = watch('destination') || {};
  const price = watch('price') || 0;
  const distance = watch('distance') || 0;
  const vehicle = watch('vehicle');

  const customTripLocation = (data, type) => {
    const location = data.location && JSON.parse(data.location);
    if (location) {
      return {
        address: data.address,
        location: location,
        index: type,
        type: type,
      };
    }
  };

  const returnError = (key) => {
    const error = errors[key]?.location?.message;
    return <FormHelperText error>{error}</FormHelperText>;
  };

  const onSubmit = (data) => {
    const tripLocation = [
      customTripLocation(data.departure, START_POINT),
      customTripLocation(data.stop, STOP_POINT),
      customTripLocation(data.destination, DESATINATION_POINT),
    ];
    const filteredTripLocation = tripLocation.filter(Boolean);

    const params = {
      customerId: data.customer,
      driverId: data.driver || null,
      vehicleClassId: data.vehicle,
      distance: distance,
      paymentSettingId: data.payment,
      tripLocations: filteredTripLocation,
    };

    const payload = {
      params: params,
      callbackSuccess: () => {
        showSuccessToast('Thêm hành trình mới thành công!');
        reset();
        navigate(CONST_ROUTER.TRIP_MANAGEMENT);
      },
      callbackError: (isError) => {
        showErrorToast(isError);
      },
    };
    dispatch(postAddTrip(payload));
  };

  const onCancel = () => {
    reset();
  };

  const handleBackPage = () => {
    reset();
    navigate(CONST_ROUTER.TRIP_MANAGEMENT);
  };

  const handleResize = () => {
    if (gridRef.current) {
      const width = gridRef.current.clientWidth;
      setGridWidth(width);
    }
  };
  const isLocation = stop.location || departure.location || destination.location;

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    dispatch(getPaymentSystemList(''));
    dispatch(getDropdownListCustomerAvailable());
    dispatch(getProvinceList(''));
    dispatch(getVehicleClass({ page: 1, take: NOT_PAGE_SIZE }));
    dispatch(getAvailableDriver(''));
  }, []);

  useEffect(() => {
    if (listTemporaryPrice?.data) {
      setValue('price', listTemporaryPrice.data.totalMoney);
      setValue('distance', listTemporaryPrice.data.distance);
    }
  }, [listTemporaryPrice]);

  useEffect(() => {
    if (isLocation && vehicle) {
      const tripLocation = [
        customTripLocation(departure, START_POINT),
        customTripLocation(stop, STOP_POINT),
        customTripLocation(destination, DESATINATION_POINT),
      ];
      const filteredTripLocation = tripLocation.filter(Boolean);
      const params = { tripLocations: filteredTripLocation, vehicleClassId: vehicle };
      dispatch(temporaryPrice(params));
    }
  }, [stop.location, departure.location, destination.location, vehicle]);

  return (
    <>
      <BackPage title="Thêm hành trình mới" handleBackPage={handleBackPage} marginbottom={1} />
      <Card sx={{ p: 2 }}>
        <Grid container spacing={!isMobile ? 8 : 1}>
          <Grid item xs={12} sm={6} md={6} className="driver-form-data">
            <Grid className="driver-form-spacing">
              <Grid container mt={2}>
                <Grid item xs={5} sm={5} md={3} mt={1}>
                  <strong>Tên tài xế:</strong>
                </Grid>
                <Grid item xs={7} sm={7} md={9}>
                  <SelectSearch
                    options={driverList || []}
                    name="driver"
                    control={control}
                    size="small"
                    errors={errors}
                    isLoading={loadingListDriverGoogleMap}
                  />
                </Grid>
              </Grid>
              <Grid container mt={2}>
                <Grid item xs={5} sm={5} md={3} mt={1}>
                  <strong className="fz-14">
                    Tên khách hàng:<span className="required">*</span>
                  </strong>
                </Grid>
                <Grid item xs={7} sm={7} md={9}>
                  <FormControl fullWidth>
                    <SelectSearch
                      options={convertOptionsPhoneNumber(listDropdownCustomerAvailabel || [])}
                      name="customer"
                      control={control}
                      size="small"
                      errors={errors}
                      isLoading={loadingListDropDownAvailabel}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container mt={2}>
                <Grid item xs={5} sm={5} md={3} mt={1}>
                  <strong className="fz-14">
                    Điểm đi: <span className="required">*</span>
                  </strong>
                </Grid>
                <Grid item xs={7} sm={7} md={9} ref={gridRef}>
                  <FormControl fullWidth>
                    <GoogleMapAutoComplete
                      name="departure.address"
                      control={control}
                      errors={errors?.departure?.location}
                      id="departure"
                      type={'departure'}
                      setValue={setValue}
                      clearErrors={clearErrors}
                      getValues={getValues}
                      STOP={STOP}
                      DEPARTURE={DEPARTURE}
                      DESINATION={DESINATION}
                      gridWidth={gridWidth}
                    />
                  </FormControl>

                  <Grid item xs={12}>
                    {returnError('departure')}
                  </Grid>
                </Grid>
              </Grid>

              <Grid container mt={2}>
                <Grid item xs={5} sm={5} md={3} mt={1}>
                  <strong className="fz-14">Điểm dừng:</strong>
                </Grid>
                <Grid item xs={7} sm={7} md={9} ref={gridRef}>
                  <FormControl fullWidth>
                    <GoogleMapAutoComplete
                      name="stop.address"
                      control={control}
                      errors={errors}
                      id="stop"
                      type="stop"
                      setValue={setValue}
                      clearErrors={clearErrors}
                      getValues={getValues}
                      STOP={STOP}
                      DEPARTURE={DEPARTURE}
                      DESINATION={DESINATION}
                      gridWidth={gridWidth}
                    />
                  </FormControl>

                  <Grid item xs={12}>
                    {returnError('stop')}
                  </Grid>
                </Grid>
              </Grid>

              <Grid container mt={2}>
                <Grid item xs={5} sm={5} md={3} mt={1}>
                  <strong className="fz-14">Điểm đến:</strong>
                </Grid>
                <Grid item xs={7} sm={7} md={9} ref={gridRef}>
                  <FormControl fullWidth>
                    <GoogleMapAutoComplete
                      name="destination.address"
                      control={control}
                      errors={errors}
                      id="destination"
                      type="destination"
                      setValue={setValue}
                      clearErrors={clearErrors}
                      getValues={getValues}
                      STOP={STOP}
                      DEPARTURE={DEPARTURE}
                      DESINATION={DESINATION}
                      gridWidth={gridWidth}
                    />
                  </FormControl>
                  <Grid item xs={12}>
                    {returnError('destination')}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={6} className="driver-form-data">
            <Grid className="driver-form-spacing">
              <Grid container mt={2}>
                <Grid item xs={5} sm={7} md={5} mt={1}>
                  <strong className="fz-14">
                    Hạng xe:<span className="required">*</span>
                  </strong>
                </Grid>
                <Grid item xs={7} sm={5} md={7}>
                  <SelectSearch
                    options={dataVehicle || []}
                    name="vehicle"
                    control={control}
                    size="small"
                    errors={errors}
                    isLoading={loadingListPaymentSystem}
                  />
                </Grid>
              </Grid>
              <Grid container mt={2}>
                <Grid item xs={5} sm={7} md={5} mt={1}>
                  <strong className="fz-14">
                    Hình thức thanh toán:<span className="required">*</span>
                  </strong>
                </Grid>
                <Grid item xs={7} sm={5} md={7}>
                  <SelectSearch
                    options={listPaymentSystem || []}
                    name="payment"
                    control={control}
                    size="small"
                    errors={errors}
                    isLoading={loadingListPaymentSystem}
                  />
                </Grid>
              </Grid>
              <Grid container mt={2}>
                <Grid item xs={5} sm={7} md={5}>
                  <strong className="fz-14">
                    Tạm tính (VNĐ):<span className="required">*</span>
                  </strong>
                </Grid>
                <Grid item xs={7} sm={5} md={7}>
                  <strong className="color-red"> {isLocation ? formatCurrency(price, false) : 0}</strong>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
      <Grid container mt={3}>
        <Grid item xs={12} display="flex" gap={3} justifyContent="flex-end">
          <ButtonCustom
            fullWidth={false}
            color="error"
            title={lang.button.actions.cancel}
            variant="contained"
            onClick={onCancel}
          />
          <ButtonCustom
            fullWidth={false}
            title={lang.button.actions.add}
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          />
        </Grid>
      </Grid>
      <CustomBackdrop open={loadingAddTrip} />
    </>
  );
}
