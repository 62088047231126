import { combineReducers } from '@reduxjs/toolkit';
import { authSlice } from './auth';
import { vehicleClass } from './vehicleClass';
import { customer } from './customer';
import { driver } from './driver';
import { account } from './account';
import { trip } from './trip';
import { priceSystem } from './priceSystem';
import { paymentSystem } from './paymentSystem';
import { notificationSlice } from './notification';
import { statistic } from './statictis';
import { path } from './path';
import { deleteAccount } from './deleteAccount';
import { offer } from './offer';
import { googleMapSlice } from './googleMapApi';
import { banner } from './banner';

const rootReducer = combineReducers({
  [authSlice.name]: authSlice.reducer,
  [vehicleClass.name]: vehicleClass.reducer,
  [customer.name]: customer.reducer,
  [driver.name]: driver.reducer,
  [account.name]: account.reducer,
  [trip.name]: trip.reducer,
  [priceSystem.name]: priceSystem.reducer,
  [paymentSystem.name]: paymentSystem.reducer,
  [notificationSlice.name]: notificationSlice.reducer,
  [statistic.name]: statistic.reducer,
  [path.name]: path.reducer,
  [deleteAccount.name]: deleteAccount.reducer,
  [offer.name]: offer.reducer,
  [googleMapSlice.name]: googleMapSlice.reducer,
  [banner.name]: banner.reducer,
});

export default rootReducer;
