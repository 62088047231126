import { Box, Toolbar, Container, useMediaQuery } from '@mui/material';
import MainNav from './NavMain';
import { Outlet, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Footer from '../Footer';
import * as signalR from '@microsoft/signalr';
import { CONST_ROUTER, PAGE_SIZE_30, typeObjectNotification } from '@/shared/constant/common';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '@/hooks/useAuth';
import {
  getNotification1,
  getUnredTotalNotification,
  readNotification,
  notificationData,
} from '@/redux/slices/notification';
import { toast } from 'react-toastify';
import {
  ReceiveMessageBookADriver,
  ReceiveMessageWhenDriverAccept,
  ReceiveMessageWhenCustomerOrDriverCancelTrip,
  ReceiveMessageWhenDriverFilldWaitingFee,
  ReceiveMessageWhenDriverFinishedWaiting,
  ReceiveMessageWhenDriverNearBy,
  ReceiveMessageWhenDriverRefuses,
  ReceiveMessageWhenDriverStartWaiting,
} from '@/shared/constant/text';
import { playAudio } from '@/shared/utils/common';
import { API_URL } from '@/shared/config/setting';

export default function DashBoard() {
  const isMobile = useMediaQuery('(max-width:600px)');
  const [isNavOpen, setIsNavOpen] = useState<boolean>(isMobile ? false : true);
  const dispatch = useDispatch<any>();
  const dataSelector = useSelector(notificationData);
  const [totalNotification, setTotalNotification] = useState(0);
  const [listBadgeNotification, setListBadgeNotification] = useState<any>([]);
  const [clickedItems, setClickedItems] = useState(new Array(listBadgeNotification.length).fill(false));
  const listNotification = dataSelector?.listNotification1;
  const { token } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      dispatch(getNotification1({ pageIndex: 1, pageSize: PAGE_SIZE_30 }));
      dispatch(getUnredTotalNotification());
    }
  }, [token]);

  useEffect(() => {
    if (listNotification) {
      const dataNotifiApi =
        listNotification?.data?.length > 0 &&
        listNotification.data.map((item) => ({
          data: item?.data,
          isRead: item?.isRead,
          idNotification: item?.id,
          type: item?.type,
          creationTime: item?.creationTime,
        }));
      setListBadgeNotification(dataNotifiApi);
    }
  }, [listNotification]);

  useEffect(() => {
    const isNumberNotRead = dataSelector?.totalNotificationUnread?.data || 0;
    setTotalNotification(isNumberNotRead || 0);
  }, [dataSelector?.totalNotificationUnread]);

  const showInfoToast = (message, duration = 5000, id, idNotification) => {
    const handleClick = () => {
      const payload = {
        id: idNotification,
        data: {
          notificationId: idNotification,
        },
        callbackSuccess: () => {
          dispatch(getNotification1({ pageIndex: 1, pageSize: PAGE_SIZE_30 }));
          dispatch(getUnredTotalNotification());
        },
      };
      idNotification && dispatch(readNotification(payload));
      navigate(CONST_ROUTER.TRIP_DETAIL1_ITEM(id));
    };

    toast.info(<div onClick={handleClick} dangerouslySetInnerHTML={{ __html: message }} />, {
      autoClose: duration,
    });
  };

  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${API_URL}/notificationHub`, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
        accessTokenFactory: async () => {
          return token || '';
        },
      })
      .withAutomaticReconnect()
      .build();

    const handleReceiveMessage = (ms, messageType) => {
      const messageObject1 = JSON.parse(ms);
      // debug server dev
      console.log('messageObject1', messageObject1);
      if (messageObject1?.NotificationId) {
        try {
          // debug server dev
          console.log('messageObject1?.NotificationId', messageObject1?.NotificationId);
          connection.invoke('ConfirmMessageReceived', messageObject1.NotificationId);
        } catch (error) {
          // debug server dev
          console.log('Not ID Notification');
          console.error('Failed to confirm message receipt:', error);
        }
      }
      if (messageType === ReceiveMessageBookADriver) {
        playAudio();
      }
      dispatch(getNotification1({ pageIndex: 1, pageSize: PAGE_SIZE_30 }));
      dispatch(getUnredTotalNotification());
      showInfoToast(
        typeObjectNotification(messageObject1, messageType),
        4500,
        messageObject1?.id,
        messageObject1?.NotificationId,
      );
    };
    const startConnection = async () => {
      try {
        await connection.start();
        connection.invoke('JoinGroup');
      } catch (error) {}
    };
    startConnection();
    connection.on('ReceiveMessageBookADriver', (ms) => handleReceiveMessage(ms, ReceiveMessageBookADriver));
    connection.on('ReceiveMessageWhenDriverAccept', (ms) => handleReceiveMessage(ms, ReceiveMessageWhenDriverAccept));
    connection.on('ReceiveMessageWhenDriverRefuses', (ms) => handleReceiveMessage(ms, ReceiveMessageWhenDriverRefuses));
    connection.on('ReceiveMessageWhenCustomerOrDriverCancelTrip', (ms) =>
      handleReceiveMessage(ms, ReceiveMessageWhenCustomerOrDriverCancelTrip),
    );
    connection.on('ReceiveMessageWhenDriverStartWaiting', (ms) =>
      handleReceiveMessage(ms, ReceiveMessageWhenDriverStartWaiting),
    );
    connection.on('ReceiveMessageWhenDriverFinishedWaiting', (ms) =>
      handleReceiveMessage(ms, ReceiveMessageWhenDriverFinishedWaiting),
    );
    connection.on('ReceiveMessageWhenDriverFilldWaitingFee', (ms) =>
      handleReceiveMessage(ms, ReceiveMessageWhenDriverFilldWaitingFee),
    );
    connection.on('ReceiveMessageWhenDriverNearBy', (ms) => handleReceiveMessage(ms, ReceiveMessageWhenDriverNearBy));

    // connection.on('ReceiveMessageWhenDriverEditWaittingFee', (ms) => handleReceiveMessage(ms, 5));
    // connection.on('ReceiveMessageWhenDriverConfirmArrived', handleReceiveMessage);
    // connection.on('ReceiveMessageWhenDriverConfirmFinished', handleReceiveMessage);
    // connection.on('ReceiveMessageWhenCustomerHasPaid', handleReceiveMessage);

    return () => {
      connection.off('ReceiveMessageBookADriver', (ms) => handleReceiveMessage(ms, ReceiveMessageBookADriver));
      connection.off('ReceiveMessageWhenDriverAccept', (ms) =>
        handleReceiveMessage(ms, ReceiveMessageWhenDriverAccept),
      );
      connection.off('ReceiveMessageWhenDriverRefuses', (ms) =>
        handleReceiveMessage(ms, ReceiveMessageWhenDriverRefuses),
      );
      connection.off('ReceiveMessageWhenCustomerOrDriverCancelTrip', (ms) =>
        handleReceiveMessage(ms, ReceiveMessageWhenCustomerOrDriverCancelTrip),
      );
      connection.off('ReceiveMessageWhenDriverStartWaiting', (ms) =>
        handleReceiveMessage(ms, ReceiveMessageWhenDriverStartWaiting),
      );
      connection.off('ReceiveMessageWhenDriverFinishedWaiting', (ms) =>
        handleReceiveMessage(ms, ReceiveMessageWhenDriverFinishedWaiting),
      );
      connection.off('ReceiveMessageWhenDriverFilldWaitingFee', (ms) =>
        handleReceiveMessage(ms, ReceiveMessageWhenDriverFilldWaitingFee),
      );
      connection.off('ReceiveMessageWhenDriverNearBy', (ms) =>
        handleReceiveMessage(ms, ReceiveMessageWhenDriverNearBy),
      );
      // connection.on('ReceiveMessageWhenDriverEditWaittingFee', (ms) => handleReceiveMessage(ms, 5));
      // connection.on('ReceiveMessageWhenDriverConfirmArrived', handleReceiveMessage);
      // connection.on('ReceiveMessageWhenDriverConfirmFinished', handleReceiveMessage);
      // connection.on('ReceiveMessageWhenCustomerHasPaid', handleReceiveMessage);
    };
  }, []);

  return (
    <Box display="flex">
      <MainNav
        setIsNavOpen={setIsNavOpen}
        totalNotification={totalNotification}
        listBadgeNotification={listBadgeNotification}
        clickedItems={clickedItems}
        setClickedItems={setClickedItems}
      />
      <Box
        className={`main-component ${isNavOpen && !isMobile && 'open'}`}
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
        }}
      >
        <Toolbar />
        <Container maxWidth={false} sx={{ mb: 4, flex: 1 }}>
          <Outlet />
        </Container>
        <Footer />
      </Box>
    </Box>
  );
}
