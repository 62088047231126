import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Api from '@/api';
import {
  DELETE_PRICE_SYTEM,
  PRICE_SYSTEM,
  PRICE_SYSTEM_AREA,
  PRICE_SYSTEM_DETAIL,
} from '@/shared/constant/constantUrl';

const initialState = {
  listPriceSystem: [],
  loadingListPriceSystem: false,
  priceSystemDetail: [],
  loadingPriceSystemDetail: false,
  loadingEditPriceSystem: false,
  loadingDeletePriceSystem: false,
  listProvince: [],
  loadingListProvince: false,
  loadingAddPriceSystem: false,
  loadingPutImageBanner: false,
};

export const getPriceSystemList = createAsyncThunk('getPriceSystemList', async (params: any) => {
  try {
    const response = await Api.get(PRICE_SYSTEM, { params });
    return response?.data;
  } catch (error: any) {
    return error;
  }
});

export const addPriceSystem = createAsyncThunk(
  'addPriceSystem/post',
  async ({ params, callbackSuccess, callbackError }: any) => {
    try {
      const response = await Api.post(PRICE_SYSTEM, params);
      callbackSuccess();
      return response?.data;
    } catch (error: any) {
      callbackError(error?.data?.message || error?.data?.Message);
      return error;
    }
  },
);

export const putEditPriceSystem = createAsyncThunk(
  'putPriceSystem/editPriceSystem',
  async ({ params, callbackSuccess, callbackError }: any) => {
    try {
      const response = await Api.put(PRICE_SYSTEM, params);
      callbackSuccess();
      return response?.data;
    } catch (error: any) {
      callbackError(error?.data?.message || error?.data?.Message);
      return error;
    }
  },
);

export const putImageBanner = createAsyncThunk(
  'putPriceSystem/putImageBanner',
  async ({ params, callbackSuccess, callbackError }: any) => {
    try {
      const response = await Api.put(PRICE_SYSTEM, params);
      callbackSuccess();
      return response?.data;
    } catch (error: any) {
      const errorData = error?.data?.message || error?.data?.Message;
      callbackError(errorData);
      return error;
    }
  },
);

export const getProvinceList = createAsyncThunk('getProvinceList', async (params: any) => {
  try {
    const response = await Api.get(PRICE_SYSTEM_AREA, { params });
    return response?.data;
  } catch (error: any) {
    return error;
  }
});

export const deletePriceSystem = createAsyncThunk(
  'deletePriceSystem/delete',
  async ({ params, callbackSuccess, callbackError }: any) => {
    try {
      const response = await Api.delete(DELETE_PRICE_SYTEM(params.id), params);
      callbackSuccess();
      return response?.data;
    } catch (error: any) {
      callbackError(error?.data?.message || error?.data?.Message);
      return error;
    }
  },
);

export const getPriceSystemDetail = createAsyncThunk('getPriceSystemDetail', async (id: string) => {
  try {
    const response = await Api.get(PRICE_SYSTEM_DETAIL(id));
    return response?.data;
  } catch (error: any) {
    return error;
  }
});

export const priceSystem = createSlice({
  name: 'priceSystem',
  initialState,
  reducers: {
    clearPriceSystemDetail: (state) => {
      state.priceSystemDetail = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPriceSystemList.pending, (state) => {
        state.loadingListPriceSystem = true;
      })
      .addCase(getPriceSystemList.fulfilled, (state, action) => {
        state.loadingListPriceSystem = false;
        state.listPriceSystem = action.payload;
      })
      .addCase(getPriceSystemList.rejected, (state) => {
        state.listPriceSystem = [];
      })

      .addCase(getPriceSystemDetail.pending, (state) => {
        state.loadingPriceSystemDetail = true;
      })
      .addCase(getPriceSystemDetail.fulfilled, (state, action) => {
        state.loadingPriceSystemDetail = false;
        state.priceSystemDetail = action.payload;
      })
      .addCase(getPriceSystemDetail.rejected, (state) => {
        state.priceSystemDetail = [];
      })

      .addCase(getProvinceList.pending, (state) => {
        state.loadingListProvince = true;
      })
      .addCase(getProvinceList.fulfilled, (state, action) => {
        state.loadingListProvince = false;
        state.listProvince = action.payload;
      })
      .addCase(getProvinceList.rejected, (state) => {
        state.listProvince = [];
      })
      //------------------------Edit--------------------------
      .addCase(putEditPriceSystem.pending, (state) => {
        state.loadingEditPriceSystem = true;
      })
      .addCase(putEditPriceSystem.fulfilled, (state, action) => {
        state.loadingEditPriceSystem = false;
      })
      .addCase(putEditPriceSystem.rejected, (state) => {})
      // ------------------------Delete--------------------------
      .addCase(deletePriceSystem.pending, (state) => {
        state.loadingDeletePriceSystem = true;
      })
      .addCase(deletePriceSystem.fulfilled, (state, action) => {
        state.loadingDeletePriceSystem = false;
      })
      .addCase(deletePriceSystem.rejected, (state) => {})
      // ------------------------Add--------------------------
      .addCase(addPriceSystem.pending, (state) => {
        state.loadingAddPriceSystem = true;
      })
      .addCase(addPriceSystem.fulfilled, (state, action) => {
        state.loadingAddPriceSystem = false;
      })
      .addCase(addPriceSystem.rejected, (state) => {})

      .addCase(putImageBanner.pending, (state) => {
        state.loadingPutImageBanner = true;
      })
      .addCase(putImageBanner.fulfilled, (state, action) => {
        state.loadingPutImageBanner = false;
      })
      .addCase(putImageBanner.rejected, (state) => {
        state.loadingPutImageBanner = false;
      });
  },
});

export const { actions } = priceSystem;
export const priceSystemData = (state) => state.priceSystem;
export default priceSystem.reducer;
export const { clearPriceSystemDetail } = priceSystem.actions;
