import {
  LIST_OFFER,
  LIST_PROVINCES,
  DETAIL_OFFER,
  PUT_OFFER,
  GET_BANNER,
  POST_BANNER,
} from '@/shared/constant/constantUrl';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Api from '@/api';
import { formData } from '@/shared/constant/common';

const initialState = {
  loadingGetBanner: false,
  listGetBanner: [],
  loadingPostBanner: false,
};

export const getBanner = createAsyncThunk('getBanner/get', async () => {
  try {
    const response = await Api.get(GET_BANNER);
    return response?.data;
  } catch (error: any) {
    return error;
  }
});

export const postBanner = createAsyncThunk(
  'postBanner/post',
  async ({ params, callbackSuccess, callbackError }: any) => {
    try {
      const response = await Api.post(POST_BANNER, params, formData);
      callbackSuccess();
      return response?.data;
    } catch (error: any) {
      callbackError(error?.data?.message || error?.data?.Message);
      return error;
    }
  },
);

export const banner = createSlice({
  name: 'banner',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBanner.pending, (state) => {
        state.loadingGetBanner = true;
      })
      .addCase(getBanner.fulfilled, (state, action) => {
        state.loadingGetBanner = false;
        state.listGetBanner = action.payload;
      })
      .addCase(getBanner.rejected, (state) => {
        state.loadingGetBanner = false;
        state.listGetBanner = [];
      })

      .addCase(postBanner.pending, (state) => {
        state.loadingPostBanner = true;
      })
      .addCase(postBanner.fulfilled, (state, action) => {
        state.loadingPostBanner = false;
      })
      .addCase(postBanner.rejected, (state) => {
        state.loadingPostBanner = false;
      });
  },
});

export const { actions } = banner;
export const bannerData = (state) => state.banner;
export default banner.reducer;
