import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Card, FormControl, Grid } from '@mui/material';
import TitlePage from '@/components/TitlePage';
import TableComponent from '@/components/TableComponent';
import { COLS_AREA_PRICE } from '@/shared/constant/TableHead';
import { useEffect, useState } from 'react';
import { showErrorToast, showSuccessToast } from '@/hooks/useCustomToast';
import ConfirmPopup from '@/components/ConfirmPopup';
import { useNavigate } from 'react-router-dom';
import { CONST_ROUTER, NOT_PAGE_SIZE, PAGE_SIZE } from '@/shared/constant/common';
import { deletePriceSystem, getPriceSystemList, getProvinceList, priceSystemData } from '@/redux/slices/priceSystem';
import { useDispatch, useSelector } from 'react-redux';
import { parseSystemPriceData } from '@/shared/constant/dataTable';
import { useQueryParameters } from '@/shared/utils/common';
import { CustomBackdrop } from '@/components/CustomBackdrop';
import { SelectSearch } from '@/common/Form';
import { ButtonSearch } from '@/components/Buttons/ButtonSearch';
import { getVehicleClass, vehicleClassData } from '@/redux/slices/vehicleClass';

export default function SystemPriceSetting() {
  const { openPopup, ConfirmationPopup } = ConfirmPopup();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const { listVehicle } = useSelector(vehicleClassData) || {};
  const dataSelector = useSelector(priceSystemData);
  const dataApi = dataSelector.listPriceSystem.data;
  const lang = window.lang;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const queriesParams: any = useQueryParameters();
  const [queryFilter, setQueryfilter] = useState(queriesParams);

  const { listProvince, loadingListPriceSystem: isLoading } = dataSelector;
  const { data: dataVehicle } = listVehicle || {};

  const defaultValue = {
    pageIndex: 1,
    pageSize: PAGE_SIZE,
  };
  const schema = yup.object().shape({
    provinceId: yup.string().nullable(),
    vehicle: yup.string().nullable(),
  });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      provinceId: '',
      vehicle: '',
    },
  });

  useEffect(() => {
    dispatch(getPriceSystemList(defaultValue));
  }, []);

  const systemPriceDataFormated = parseSystemPriceData(dataApi, currentPage, dataSelector.listPriceSystem.totalCount);

  const handleDelete = (id) => {
    const confirmDelete = () => {
      const payload = {
        params: {
          id: id,
        },
        callbackSuccess: () => {
          setCurrentPage(1);
          showSuccessToast(lang.areaPriceSetting.successDelete);
          dispatch(getPriceSystemList(defaultValue));
        },
        callbackError: (isError) => {
          showErrorToast(isError);
        },
      };
      dispatch(deletePriceSystem(payload));
    };
    openPopup(lang.areaPriceSetting.modalText.delete, confirmDelete);
  };

  const handleEdit = (id) => {
    setIsEdit(true);
    navigate(CONST_ROUTER.AREA_PRICE_DETAIL_ITEM(id));
  };
  const handleAdd = () => {
    navigate(CONST_ROUTER.ADD_AREA_PRICE);
  };

  const handlePaging = (page: number) => {
    const data = {
      ...queryFilter,
      pageIndex: page,
      pageSize: PAGE_SIZE,
    };
    dispatch(getPriceSystemList(data));
  };

  const onSubmit = (data) => {
    setCurrentPage(1);
    const dataSubmit = {
      VehicleClassId: data.veihcle,
      ProvinceId: data.provinceId,
      pageIndex: 1,
      pageSize: PAGE_SIZE,
    };
    setQueryfilter(dataSubmit);
    dispatch(getPriceSystemList(dataSubmit));
  };

  const handleResetFilter = () => {
    reset();
    setValue('vehicle', '');
    setValue('provinceId', '');
    setCurrentPage(1);
    setQueryfilter(defaultValue);
    dispatch(getPriceSystemList(defaultValue));
  };

  useEffect(() => {
    dispatch(getProvinceList(defaultValue));
    dispatch(getVehicleClass({ page: 1, take: NOT_PAGE_SIZE }));
  }, []);

  return (
    <>
      <Grid container className="page-title border-button-back" mb={1}>
        <Grid item>
          <TitlePage title={lang.areaPriceSetting.areaPriceTitle} />
        </Grid>
      </Grid>

      <Card sx={{ p: 2, mt: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={7} md={5} lg={4}>
            <FormControl fullWidth>
              <SelectSearch
                label="Khu vực"
                control={control}
                name="provinceId"
                options={listProvince || []}
                errors={errors}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={5} md={4} lg={3}>
            <FormControl fullWidth>
              <SelectSearch label="Hạng xe" control={control} name="veihcle" options={dataVehicle} errors={errors} />
            </FormControl>
          </Grid>
        </Grid>
        <ButtonSearch submit={handleSubmit(onSubmit)} resetSearch={handleResetFilter} add={handleAdd} />
      </Card>

      <Grid item xs={12} mt={1}>
        <Grid item xs={12} mt={1}>
          <TableComponent
            onDelete={handleDelete}
            onEdit={handleEdit}
            tableHead={COLS_AREA_PRICE}
            listData={systemPriceDataFormated}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            isLoading={isLoading}
            totalRecord={dataApi?.data?.length}
            totalItems={dataSelector?.listPriceSystem.totalCount}
            pagingCallback={(page) => handlePaging(page)}
          />
        </Grid>
        <ConfirmationPopup />
        <CustomBackdrop open={dataSelector?.loadingEditPriceSystem || dataSelector?.loadingDeletePriceSystem} />
      </Grid>
    </>
  );
}
